<template>
  <section class="login__box regisetr__box">
    <div class="login__bgs">
      <img src="@/assets/img/loginbg.jpg" alt=""/>
    </div>
    <div class="login__info">
      <h3 class="login__title">注册</h3>
      <div class="login__text">
        <p>欢迎使用五粮液招聘系统，请输入手机号码和密码登录。</p>
      </div>
      <div class="login__form ">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item prop="telphone">
            <span class="iconfont">&#xe78f;</span>
            <el-input placeholder="手机号" v-model="ruleForm.telphone"></el-input>
            <button class="codes" @click="sendCode" :disabled="countdown > 0 || !ruleForm.telphone">
              {{ countdown > 0 ? `${countdown}秒后重新发送` : '发送验证码' }}
            </button>
          </el-form-item>
          <el-form-item prop="code">
            <span class="iconfont">&#xe78e;</span>
            <el-input placeholder="验证码" v-model.number="ruleForm.code"></el-input>
          </el-form-item>
          <el-form-item prop="pwd">
            <span class="iconfont">&#xe790;</span>
            <el-input type="password" placeholder="密码" v-model="ruleForm.pwd"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <span class="iconfont">&#xe790;</span>
            <el-input type="password" placeholder="确认密码" v-model="ruleForm.password"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="login__btn" type="primary" @click="submitForm('ruleForm')">注册</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="login__down">
        <a href="#"></a>
        <router-link to="/login">已有账号？立即登录</router-link>
      </div>
    </div>
    <div class="login__copy">
      <p>四川安吉物流集团有限公司Copyright2023  蜀ICP备2023013433号-1    公安局备案号5115000334  <a href="#">技术支持</a>：<a href="#">今网科技</a></p>
    </div>
  </section>
</template>

<script>
export default {
data(){
  var validatePass = (rule, value, callback) => {
    if (value === '') {
      callback(new Error('请输入密码'));
    } else {
      if (this.ruleForm.password !== '') {
        this.$refs.ruleForm.validateField('password');
      }
      callback();
    }
  };
  var validatePass2 = (rule, value, callback) => {
    if (value === '') {
      callback(new Error('请再次输入密码'));
    } else if (value !== this.ruleForm.pwd) {
      callback(new Error('两次输入密码不一致!'));
    } else {
      callback();
    }
  };

  var validatePassword = (rule, value, callback) => {
    if (!/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*.])[\da-zA-Z~!@#$%^&*.]{8,}$/.test(value)) {
      callback(new Error('密码必须包含至少一个字母和一个数字和一个特殊符号'))
    } else {
      callback()
    }
  };
  return {
    menuNumber: 5,
    contactData: [],
    numble: 0,
    countdown: 0, 
    intervalId: null, 
    ruleForm: {
        telphone: '',
        code: '',
        pwd: '',
        password:'',
    },
    rules: {
        telphone: [
          { required: true, message: '请输入电话', trigger: ['blur', 'change'] },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
        ],
        code: [
            { required: true, message: '请输入验证码', trigger: 'blur' },
            { type: 'number', message: '请输入数字',trigger: 'change'}
        ],
        pwd: [
          { validator: validatePass, trigger: 'blur' },
          { min: 8, max: 20, message: '密码长度在8到20个字符', trigger: ['blur', 'change'] }
        ],
        password: [
          { validator: validatePass2, trigger: 'blur' },
          { min: 8, max: 20, message: '密码长度在8到20个字符', trigger: ['blur', 'change'] },
          { validator: validatePassword, message: '密码必须包含至少一个字母和一个数字和一个特殊符号!', trigger: ['blur', 'change'] }
        ],
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const {data: res} = await this.$http.post("reg", this.ruleForm);
          console.log(res)
          if(res.status == 0){
            this.$message.error(res.info);
          }else{
            this.$message({
              message: res.info,
              type: 'success'
            });
            this.$refs[formName].resetFields();
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async sendCode() {
      // 开始倒计时
      this.startCountdown(60); // 60秒倒计时
      const {data: res} = await this.$http.post(`sendRegCode?telphone=${this.ruleForm.telphone}`);
      if(res.status == 0){
        this.$message.error(res.info);
      }else{
        this.$message({
          message: res.info,
          type: 'success'
        });
      }
    },
    startCountdown(seconds) {
      this.countdown = seconds;
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown -= 1;
        } else {
          clearInterval(this.intervalId);
          this.intervalId = null;
        }
      }, 1000);
    }
  }
}
</script>

<style>

</style>